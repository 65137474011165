<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="empresas"
      :loading="loading"
      :paginate="true"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Parâmetros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <FilterParamsPosVendas
            @selectedFilters="fetchEmpresas((filters = $event))"
          />
        </v-toolbar>
      </template>

      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>

      <template v-slot:[`item.id_empresa`]="{ item }">
        {{ formatEmpresa(item.id_empresa) }}
      </template>

      <template v-slot:[`item.os`]="{ item }">
        <IconBottom
          :name="'bond'"
          v-can-access="777"
          @click="openModal(item)"
        />
      </template>
    </BaseTable>
    <DraggableParam
      :dialog="dialogDraggable"
      :empresa="selectedCompany"
      @close="dialogDraggable = false"
    />
  </div>
</template>

<script>
import BaseTable from "@/components/shared/NewBaseTable.vue";
import sistema from "@/services/http/sistemaService";
import statusAtivo from "@/mixins/statusAtivo";
import bandeiras from "@/mixins/bandeiras";
import DraggableParam from "./DraggableParamPosVendas.vue";
import FilterParamsPosVendas from "./FilterParamsPosvendas.vue";
import IconBottom from "@/components/shared/bottons/IconBottom";

export default {
  components: {
    BaseTable,
    DraggableParam,
    FilterParamsPosVendas,
    IconBottom
  },

  mixins: [statusAtivo, bandeiras],

  data() {
    return {
      loading: false,
      headers: [
        { text: "Bandeira", value: "id_band" },
        { text: "Empresa", value: "id_empresa" },
        { text: "Ordens de serviço", value: "os", align: "center" }
      ],
      dialogDraggable: false,
      selectedCompany: {},
      empresas: [],
      filters: ""
    };
  },

  methods: {
    async fetchEmpresas(filter = {}) {
      let {
        data: { data }
      } = await sistema()
        .empresa()
        .show({ per_page: -1, id_band: "2,4", ...filter });
      this.empresas = data.sort((a, b) =>
        a.nome_empresa.toLowerCase() > b.nome_empresa.toLowerCase() ? 1 : -1
      );
    },

    async openModal(company) {
      this.selectedCompany = Object.assign({}, company);
      this.dialogDraggable = true;
    },

    closeModal() {
      this.$refs.form.reset();
    },

    formatEmpresa(value) {
      let empresa = this.empresas.find(item => item.id_empresa == value);

      return empresa?.nome_empresa.toUpperCase();
    }
  },

  mounted() {
    this.fetchEmpresas();
  }
};
</script>
