<template>
  <v-container class="container-card">
    <v-card>
      <ParametrosPosVendas />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ParametrosPosVendas from "@/components/comissao/deptoPosVendas/ParametrosPosVendas";
export default {
  components: {
    ParametrosPosVendas
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consulta Pâmetros Pós-Vendas");
  }
};
</script>

<style></style>
