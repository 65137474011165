<template>
  <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
    <v-card>
      <v-card-title class="flex-column align-start">
        <span class="headline">
          Vincular OS - Empresa: {{ empresa.nome_empresa }}
        </span>
        <v-form ref="form">
          <BaseSelect
            label="Indicador"
            v-model="indicador"
            service="sistemaService.indicadores"
            item-text="indicador"
            item-value="id_indicador"
            prepend-inner-icon="mdi-finance"
            clearable
            :rules="rules.required"
            :filters="{ ativo: 'S' }"
            :hasCode="true"
            :multiple="false"
            @change="vinculo()"
        /></v-form>
        <v-alert dense text :icon="false" type="warning" v-if="!indicador">
          <v-icon class="mr-2" color="warning">mdi-alert-circle</v-icon>
          Selecione um indicador para visualizar o conteúdo
        </v-alert>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="red">mdi-close</v-icon>Ordens de
              Serviço não Vinculadas
            </h4>
            <v-btn
              class="mb-3"
              color="success"
              @click="addAllOS()"
              :disabled="ordensNaoVinculadas.length == 0"
            >
              Adicionar todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area exclude-companies"
              :list="ordensNaoVinculadas"
              group="equipes"
              @change="moveAdded()"
            >
              <div
                class="v-list-item__content list"
                v-for="ordens in ordensNaoVinculadas"
                :key="ordens.id_estoque_pecas"
              >
                <div class="list-equipe-items">
                  <div class="list-equipe">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{ ordens.os }}</strong>
                    </v-row>
                  </div>
                  <div class="mr-4">
                    <v-btn icon @click="addOrdemServico(ordens)">
                      <v-icon color="green">mdi-arrow-right-bold</v-icon>
                    </v-btn>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>

          <v-divider vertical></v-divider>

          <v-col>
            <h4 class="mb-2">
              <v-icon class="mr-1" color="green">mdi-check</v-icon>Ordens de
              Serviço Vinculadas
            </h4>
            <v-btn
              class="mb-3"
              color="error"
              @click="removeAllOS()"
              :disabled="ordensVinculadas.length == 0"
            >
              Remover todas
            </v-btn>
            <draggable
              handle=".handle"
              class="draggable-area include-companies"
              :list="ordensVinculadas"
              group="equipes"
              @change="moveRemove()"
            >
              <div
                class="v-list-item__content"
                v-for="ordens in ordensVinculadas"
                :key="ordens.id_equipe_ordens"
              >
                <div class="list-equipe-participantes-items">
                  <div>
                    <v-btn
                      class="mr-4"
                      icon
                      @click="removeOrdemServico(ordens)"
                    >
                      <v-icon color="red">mdi-arrow-left-bold</v-icon>
                    </v-btn>
                  </div>
                  <div class="list-equipe mr-4">
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${ordens.descricao} -`
                      }}</strong>

                      {{ ordens.ordens }}
                    </v-row>
                    <v-row class="ml-0">
                      <strong class="mr-1">{{
                        `${ordens.cod_param} -`
                      }}</strong>

                      {{ ordens.indicador }}
                    </v-row>
                  </div>
                </div>
              </div>
            </draggable>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="red darken-1" text @click="close()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import BaseSelect from "@/components/shared/BaseSelectService";
import comissao from "@/services/http/comissaoService";

export default {
  name: "DraggableEquipesCampanha",

  components: {
    draggable,
    BaseSelect
  },

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    empresa: {
      type: Object
    }
  },

  data() {
    return {
      indicador: "",
      rules: {
        required: [v => !!v || "Selecione um indicador"]
      },
      ordensNaoVinculadas: [],
      ordensVinculadas: []
    };
  },

  watch: {
    empresa(value) {
      this.default();
      return value;
    }
  },

  methods: {
    async vinculo() {
      let tipoOs = await this.fetchTipoOS();

      this.ordensVinculadas = await this.fetchParametrosPosVendas();

      let idVinculados = this.vinculados.map(item => {
        return parseInt(item.id_empresa);
      });

      this.ordensNaoVinculadas = tipoOs.filter(
        item => !idVinculados.includes(item.id_empresa)
      );
    },

    async fetchTipoOS() {
      if (!this.indicador) return [];
      let {
        data: { data }
      } = await comissao()
        .tipoOS()
        .show({ per_page: -1, id_band: this.empresa.id_band });
      return data;
    },

    async fetchParametrosPosVendas() {
      if (!this.indicador) return [];
      let {
        data: { data }
      } = await comissao()
        .parametrosPosVendas()
        .show({
          per_page: -1,
          id_empresa: this.empresa.id_empresa,
          cod_param: this.indicador
        });
      return data;
    },

    async addAllOS() {
      if (!this.$refs.form.validate()) return;
      try {
        let allPost = this.ordensNaoVinculadas.map(item => {
          const form = {
            cod_band: item.id_band,
            cod_param: this.indicador.toString(),
            id_empresa: this.empresa.id_empresa,
            os: item.tipo,
            ativo: "S"
          };
          return comissao()
            .parametrosPosVendas()
            .store(form, {
              notification: true,
              message: "OS vinculada com sucesso!"
            });
        });

        await Promise.all(allPost);
      } catch (error) {
        console.log(error);
        this.$notify({
          type: "error",
          text: `Erro ao adicionar equipe`
        });
      } finally {
        this.vinculo();
      }
    },
    async removeAllOS() {
      try {
        let allDelete = this.ordensVinculadas.map(item => {
          return comissao()
            .parametrosPosVendas(item.id_estoque_pecas)
            .delete(
              {},
              {
                notification: true,
                message: { success: "OS desvinculada com sucesso!" }
              }
            );
        });
        await Promise.all(allDelete);
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover equipes`
        });
      } finally {
        this.vinculo();
      }
    },

    async addOrdemServico(item) {
      if (!this.$refs.form.validate()) return;

      const form = {
        cod_band: item.id_band,
        cod_param: this.indicador.toString(),
        id_empresa: this.empresa.id_empresa,
        os: item.tipo,
        ativo: "S"
      };
      await comissao()
        .parametrosPosVendas()
        .store(form, {
          notification: true,
          message: { success: "OS vinculada com sucesso!" }
        });

      this.vinculo();
    },

    async removeOrdemServico(item) {
      try {
        await comissao()
          .parametrosPosVendas(item.id_estoque_pecas)
          .delete(
            {},
            {
              notification: true,
              message: { success: "OS removida com sucesso!" }
            }
          );
      } catch (error) {
        this.$notify({
          type: "error",
          text: `${error.response.data.message}` || `Erro ao remover equipe`
        });
      } finally {
        this.vinculo();
      }
    },

    moveAdded(evt) {
      let ordens = {};
      const { removed } = evt;

      if (removed) {
        ordens = removed.element;
        this.addOrdemServico(ordens);
      }
    },

    moveRemove(evt) {
      let ordens = {};
      const { removed } = evt;

      if (removed) {
        ordens = removed.element;
        this.removeOrdemServico(ordens);
      }
    },

    close() {
      this.$emit("close");
      this.$refs.form.reset();
    },

    default() {
      this.naoVinculados = [];
      this.vinculados = [];
    }
  }
};
</script>

<style scoped>
.v-list-item__content {
  padding-inline-start: 15px;
  border: 1px solid hsla(0, 0%, 31%, 0.199);
  border-radius: 10px;
  margin-bottom: 4px;
}

.draggable-area {
  min-height: 300px;
  padding: 8px;
}

.draggable-area.include-companies {
  border: 2px dashed hsla(128, 100%, 34%, 0.39);
}

.draggable-area.exclude-companies {
  border: 2px dashed hsla(347, 100%, 34%, 0.39);
}
.v-list-item__content .list {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-items {
  display: flex !important;
  justify-content: space-between;
}

.list-equipe-participantes-items {
  display: flex !important;
}

.list-equipe {
  display: flex !important;
  flex-direction: column;
}
</style>
