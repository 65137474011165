<template>
  <v-menu :close-on-content-click="false" max-width="320" offset-x>
    <template v-slot:activator="{ on }">
      <FilterBottom v-on="on" />
    </template>

    <v-card>
      <v-container>
        <BaseFilter
          prepend-inner-icon="mdi-file-table-box-multiple-outline"
          clearable
          single-line
          label="Segmento"
          item-text="descricao"
          item-value="id_band"
          service="sistemaService.tipoBandeiras"
          v-model="filters.id_band"
          :filters="{ id_band: '2,4' }"
          :isCompany="true"
          @change="handleFilter()"
        />

        <BaseFilter
          prepend-inner-icon="mdi-domain"
          clearable
          single-line
          label="Empresa"
          item-text="apelido"
          item-value="id_empresa"
          service="sistemaService.empresa"
          :filters="{
            id_band: filters.id_band,
            calcula_comissao: 'S'
          }"
          v-model="filters.id_empresa"
          :isCompany="true"
          @change="handleFilter()"
        />
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
import FilterBottom from "@/components/shared/bottons/FilterBottom";
import BaseFilter from "@/components/shared/BaseSelectService";

export default {
  name: "Filter",

  components: {
    FilterBottom,
    BaseFilter
  },

  data() {
    return {
      filters: {}
    };
  },

  methods: {
    handleFilter() {
      this.$emit("selectedFilters", this.filters);
    }
  }
};
</script>
<style scoped></style>
